// @flow

import React from "react";
import styled, { css } from "styled-components";
import Stack from "../Stack";

import defaultTheme from "../../utils/defaultTheme";
import media from "../../utils/mediaQuery";

const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.base.widthBody};
  width: 100%;
  box-sizing: border-box;

  ${({ noSpacing }) =>
    !noSpacing &&
    css`
      padding: 0 20px;

      ${media.tablet(css`
        padding: 0 ${({ theme }) => theme.base.bodyPadding};
      `)}
    `};
`;

StyledContainer.defaultProps = {
  theme: defaultTheme
};

const Container = ({ children, noSpacing, id, ...props }) => {
  return (
    <Stack justify="center" width="100%">
      <StyledContainer id={id} noSpacing={noSpacing} {...props}>
        {children}
      </StyledContainer>
    </Stack>
  );
};

export default Container;
