// @flow

export default {
  base: {
    baseFontSize: 16,
    widthBody: "1300px",
    bodyPadding: "4rem",
    widthBreakpointMediumMobile: 414,
    widthBreakpointLargeMobile: 576,
    widthBreakpointTablet: 768,
    widthBreakpointDesktop: 992,
    widthBreakpointLargeDesktop: 1200,
    spaceXXXSmall: "2px",
    spaceXXSmall: "4px",
    spaceXSmall: "8px",
    spaceSmall: "12px",
    spaceMedium: "16px",
    spaceLarge: "24px",
    spaceXLarge: "32px",
    spaceXXLarge: "40px",
    spaceXXXLarge: "52px"
  },
  rtl: false
};
