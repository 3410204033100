// @flow

import React from "react";
import styled, { css } from "styled-components";
import Stack from "../Stack";
import { useInView } from "react-intersection-observer";

import defaultTheme from "../../utils/defaultTheme";
import Container from "../Container";
import Button from "../Button";

const StyledCta = styled.div`
  width: 100%;
  min-height: 550px;
  box-sizing: border-box;
  display: flex;
  padding-top: 100px;
  align-items: flex-start;
  background-color: rgba(5, 5, 38);
`;

StyledCta.defaultProps = {
  theme: defaultTheme
};

const StyledImageWrapper = styled.div`
  width: 50%;
  padding-top: 30px;
`;

const StyledContentWrapper = styled.div``;

const StyledLargeText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  opacity: 0.85;
`;

const StyledImage = styled.img`
  margin-bottom: 0;
`;

const StyledHeading = styled.h2`
  font-size: 2.5rem;
  line-height: 1.3;
  color: #fff;
`;

const Cta = () => {
  const [ref, inView, entry] = useInView({});

  return (
    <StyledCta>
      <Container>
        <Stack flex direction="row" align="start">
          <StyledContentWrapper>
            <Stack spacing="medium">
              <StyledHeading>
                Chcete pomoci <br />s vaším e-shopem?
              </StyledHeading>
              <StyledLargeText ref={ref}>
                O Shopify nejen píšeme, ale umíme na něm i stavět e-shopy.
                <br /> ozvěte se nám jestli potřebujete s nečím pomoci.
              </StyledLargeText>
              <Button to="/kontakt">Vývoj na míru</Button>
            </Stack>
          </StyledContentWrapper>
        </Stack>
      </Container>
    </StyledCta>
  );
};

export default Cta;
