import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled(({ className, to, children }) => (
  <Link className={className} to={to}>
    {children}
  </Link>
))`
  font-family: "Montserrat";
  font-size: 1rem;
  text-decoration: none;
  background: #4d3e9c;
  padding: 18px 20px 17px;
  color: #fff;
  transition: 300ms ease-in-out;
  border-radius: 4px;
  display: inline-block;

  &:hover {
    color: #fff;
    background: #323387;
  }
`;

export default Button;
