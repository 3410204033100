// @flow

import React, { Children } from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import Stack from "../Stack";
import { useInView } from "react-intersection-observer";

import defaultTheme from "../../utils/defaultTheme";
import Container from "../Container";
import Button from "../Button";

const StyledFooter = styled.div`
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  display: flex;
  padding-top: 100px;
  align-items: flex-start;
  background-color: #020215;
  color: #fff;
  font-family: "Montserrat", serif;
`;

StyledFooter.defaultProps = {
  theme: defaultTheme,
};

const Logo = styled.div`
  font-weight: 800;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledFooterItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFooterTitle = styled.div`
  font-weight: 800;
`;

const FooterItemList = ({ title, children }) => {
  return (
    <Stack direction="column" grow={false} stretch={false}>
      <StyledFooterTitle>{title}</StyledFooterTitle>
      {children}
    </Stack>
  );
};

const Footer = () => {
  const [ref, inView, entry] = useInView({});

  return (
    <StyledFooter>
      <Container>
        <Stack flex direction="row" align="center" spacing="xxLarge">
          <Logo>České Shopify</Logo>
          <FooterItemList title="Aplikace">
            <StyledLink to="/eet">Fakturoid pro Shopify</StyledLink>
          </FooterItemList>
          <FooterItemList title="Shopify">
            <StyledLink to="/#zacatky">Začátky</StyledLink>
            <StyledLink to="/kontakt/">Kontakt</StyledLink>
          </FooterItemList>
        </Stack>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
