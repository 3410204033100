import React, { useEffect, useState } from "react";

const useMatchMedia = mediaQuery => {
  const [matches, setMatches] = useState(null);
  const media = typeof window === "object" && window.matchMedia(mediaQuery);

  useEffect(() => {
    function calcMatches() {
      setMatches(media.matches);
    }

    calcMatches();

    window.addEventListener("resize", calcMatches);
    return () => {
      window.removeEventListener("resize", calcMatches);
    };
  }, []);

  return matches;
};

export default useMatchMedia;
