// @flow

import React from "react";
import styled, { css } from "styled-components";
import Stack from "../Stack";

import defaultTheme from "../../utils/defaultTheme";

const StlyedWrapper = styled.div`
  background-color: #fff;
  transform: scale(1);
  transition: 300ms ease-in-out;
  ${({ activeCta }) =>
    activeCta &&
    css`
      transform: scale(0.9);
      border-radius: 9px;
      overflow: hidden;
    `};
`;

const AnimateContainer = ({ children }) => {
  return <StlyedWrapper>{children}</StlyedWrapper>;
};

export default AnimateContainer;
