import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import React, { useEffect, useState } from "react";
import useMatchMedia from "./useMatchMedia";
import Container from "../components/Container";
import menu from "../images/list-menu.svg";
import close from "../images/close.svg";

const StyledHeader = styled.header`
  height: 89px;
  width: 100%;
  color: ${({ show, dark }) => {
    return dark && !show ? "#000" : "#fff";
  }};

  ${({ dark }) =>
    dark &&
    css`
      margin-bottom: 2rem;
    `}
`;

const StyledWrapper = styled.div`
  margin: 0 auto;
  padding: 1.2rem 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const StyledHeading = styled.h2`
  font-size: 1rem;
  color: inherit;
  font-weight: 800;
  text-decoration: none;
  margin: 0;

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    transition: 150ms;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const StyledMenu = styled.div`
  display: flex;
  padding-left: 20px;
  color: inherit;
`;

const Button = styled(({ className, to, children }) => (
  <Link className={className} to={to}>
    {children}
  </Link>
))`
  font-family: "Montserrat";
  font-size: 0.8rem;
  text-decoration: none;
  background: #fff;
  padding: 9px 20px 10px;
  color: #050526;
  margin-left: 20px;
  transition: 300ms ease-in-out;
  border-radius: 4px;

  &:hover {
    color: #fff;
    background: #323387;
  }
`;

const StyledLink = styled(({ className, to, children }) => (
  <Link className={className} to={to}>
    {children}
  </Link>
))`
  font-family: "Montserrat";
  font-size: 0.8rem;
  text-decoration: none;
  font-weight: 400;
  margin-left: 20px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
    color: inherit;
    opacity: 0.6;
    transition: opacity 150ms ease-in-out;
  }
`;

const CloseButton = styled.button`
  font-family: "Montserrat";
  color: inherit;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  & > img {
    margin-left: 10px;
  }
`;

const OpenButton = styled.button`
  font-family: "Montserrat";
  color: inherit;
  display: flex;
  align-items: center;
  & > img {
    margin-left: 10px;
  }
  -webkit-appearance: none;
  background: transparent;
  border: none;
`;

const StyledMobileMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #050527;
  z-index: 10;

  display: flex;
  align-items: center;
  flex-direction: column;

  padding-top: 20px;

  & > a {
    margin: 30px 0 0 0;
    padding: 20px 20px;
    font-size: 21px;
  }
`;

const StyledImg = styled.img`
  margin: 0;
`;

const MobileMenu = ({ children }) => {
  return <StyledMobileMenu>{children}</StyledMobileMenu>;
};

const Header = ({ siteTitle, dark }) => {
  const [show, setShown] = useState(false);
  const mobile = useMatchMedia("(max-width: 850px)");

  return (
    <StyledHeader show={show} dark={dark}>
      <StyledWrapper>
        <StyledHeading>
          <Link to="/">České Shopify</Link>
        </StyledHeading>
        {mobile ? (
          <>
            {show ? (
              <MobileMenu>
                <CloseButton
                  onClick={() => {
                    setShown(false);
                  }}
                >
                  Zavřít <StyledImg src={close} width="15" height="15" />
                </CloseButton>
                <StyledLink to="/#zacatky">Začátky</StyledLink>
                <StyledLink to="/eet">Fakturoid pro Shopify</StyledLink>
                <Button to="/kontakt">Vývoj na míru</Button>
              </MobileMenu>
            ) : (
              <OpenButton
                onClick={() => {
                  setShown(true);
                }}
              >
                Menu <StyledImg src={menu} width="15" height="15" />
              </OpenButton>
            )}
          </>
        ) : (
          <StyledMenu>
            <StyledLink to="/#zacatky">Začátky</StyledLink>
            <StyledLink to="/eet">Fakturoid pro Shopify</StyledLink>
            <Button to="/kontakt">Vývoj na míru</Button>
          </StyledMenu>
        )}
      </StyledWrapper>
    </StyledHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
