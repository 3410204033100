import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import Container from "./Container";

const StyledNextArticle = styled(Link)`
  width: 100%;
  background-color: #0d0d5c;
  color: white;
  padding: 4rem 0;
  display: block;
  text-decoration: none;
  transition: 200ms;
  margin-top: 2rem;

  &:focus,
  &:hover {
    h2 {
      text-decoration: underline;
    }
    background-color: #11164e;
  }
`;

const StyledContent = styled.div`
  margin: 0 auto;
  /* max-width: 632px; */
  /* padding: 0 1rem; */
`;

const StyledNote = styled.div`
  text-decoration: none;
  font-family: "Montserrat", serif;
  color: #f3f3f3;
`;

const StyledHeading = styled.h2`
  margin: 0.5rem 0;
`;

const NextArticle = ({ id }) => {
  const data = useStaticQuery(graphql`
    query NextArticle {
      allMarkdownRemark {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              category
            }
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      {data.allMarkdownRemark.edges.map(({ node }, i, arr) => {
        let text = false;
        let current = {};

        if (node.id === id) {
          if (arr[i + 1]) {
            text = "Pokračování";
            current = arr[i + 1];
          } else if (arr[i - 1]) {
            text = "Předchozí článek";
            current = arr[i - 1];
          }
        }

        if (text) {
          return (
            <StyledNextArticle key={node.id} to={current.node.fields.slug}>
              <Container>
                <StyledContent>
                  <StyledNote>{text}</StyledNote>
                  <StyledHeading key={current.node.id}>
                    {current.node.frontmatter.title}
                  </StyledHeading>
                </StyledContent>
              </Container>
            </StyledNextArticle>
          );
        }
        return;
      })}
    </React.Fragment>
  );
};

export default NextArticle;
