import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled, { css } from "styled-components";
import "./layout.css";
import Stack from "../components/Stack";
import Footer from "../components/Footer";
import AnimateContainer from "../components/AnimatedContainer";

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Stack spacing="none">
          {children}

          <Footer></Footer>
        </Stack>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
