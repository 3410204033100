import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled, { css } from "styled-components";

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  text-align: right;
  position: absolute;
  right: 0;
  padding-top: 90px;
  padding-right: 30px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  ${props =>
    props.currentLink &&
    css`
      background-color: red;
    `};
`;

export default ({ current, fixed }) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: [frontmatter___category] }) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              category
              order
            }
          }
        }
      }
    }
  `);

  const Display = ({ index, node, currentLink }) => (
    <div>
      {index === 0 && <h4>{node.frontmatter.category}</h4>}
      <StyledLink to={node.fields.slug} currentLink={currentLink}>
        {node.frontmatter.title}
      </StyledLink>
    </div>
  );

  const sortData = tag => {
    return data.allMarkdownRemark.edges
      .map(({ node }, i) => {
        if (node.frontmatter.category === tag) {
          return node;
        }
      })
      .filter(el => {
        return el && el;
      })
      .sort((a, b) => {
        return a.frontmatter.order - b.frontmatter.order;
      });
  };

  const categories = [
    "Je Shopify pro mě?",
    "První kručky",
    "Překlad do češtiny",
    "Platby a doprava",
    "Fakturace",
    "Srovnávače"
  ];

  return (
    <StyledNav fixed={fixed}>
      {categories.map(tag => {
        return sortData(tag).map((node, i) => {
          return (
            <Display
              node={node}
              key={node.id}
              index={i}
              currentLink={current === node.frontmatter.title}
            />
          );
        });
      })}
    </StyledNav>
  );
};
