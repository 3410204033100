// @flow
import SPACINGS_AFTER from "./consts";

import type { Props } from ".";

const getSpacingToken = ({ spaceAfter, theme }: Props) => {
  const tokens = {
    [SPACINGS_AFTER.NONE]: "0",
    [SPACINGS_AFTER.SMALLEST]: theme.base.spaceXXSmall,
    [SPACINGS_AFTER.SMALL]: theme.base.spaceXSmall,
    [SPACINGS_AFTER.NORMAL]: theme.base.spaceSmall,
    [SPACINGS_AFTER.MEDIUM]: theme.base.spaceMedium,
    [SPACINGS_AFTER.LARGE]: theme.base.spaceLarge,
    [SPACINGS_AFTER.LARGEST]: theme.base.spaceXLarge
  };
  return spaceAfter && tokens[spaceAfter];
};

export default getSpacingToken;
