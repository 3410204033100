// @flow

export const DIRECTIONS = {
  ROW: "row",
  COLUMN: "column",
  ROWREVERSE: "row-reverse",
  COLUMNREVERSE: "column-reverse"
};

export const ALIGNS = {
  START: "start",
  END: "end",
  CENTER: "center"
};

export const JUSTIFY = {
  START: "start",
  END: "end",
  CENTER: "center",
  BETWEEN: "between",
  AROUND: "around"
};

export const SPACINGS = {
  NONE: "none",
  EXTRATIGHT: "xxSmall",
  TIGHT: "xxSmall",
  CONDENSED: "xSmall",
  COMPACT: "small",
  NATURAL: "medium",
  COMFY: "large",
  LOOSE: "xLarge",
  EXTRALOOSE: "xxLarge"
};
